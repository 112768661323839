.full-section {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  scroll-snap-align: center;
  gap: 5rem;
  padding: 2rem 10vw;
  overflow-y: auto;
}

/* Adjust padding for smaller screens */
@media (max-width: 1200px) {
  .full-section {
    flex-direction: column;
    gap: 2rem;
    padding: 2rem 5vw;
  }
}

/* Further adjust padding for very small screens */
@media (max-width: 768px) {
  .full-section {
    padding: 2rem 2vw;
  }
}

.down-arrow {
  position: absolute;
  bottom: 20px;
  font-size: 1rem;
  color: black;
  background: none;
  border: none;
  text-decoration: none;
  cursor: pointer;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  overflow: hidden; /* Prevent body scrolling */
  scroll-behavior: smooth;
}

body {
  font-family: 'Inter', sans-serif;
}
.text-container{
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 1.8rem;
}

.top-text{
  font-size: 1.2rem;
}

.middle-text{
  font-size: 5rem;
}

.bottom-text{
  font-size: 1.2rem;
}

@media (max-width: 1300px) {
  .top-text {
    font-size: 0.9rem;
  }
  .middle-text {
    font-size: 2.8rem;
  }
  .bottom-text {
    font-size: 0.9rem;
  }
}

@media (max-width: 768px) {
  .text-container{
    gap: 1rem;
  }
}




.navbar{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  color: white;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.navbar-logo{
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
  text-decoration: none;
}

.navbar-logo a{
  color: white;
  text-decoration: none;
}

.navbar-links{
  display: flex;
  gap: 3rem;
}

.navbar-links a {
  color: white;
  text-decoration: none;
  font-size: 1rem;
}

.navbar-links a:hover {
  text-decoration: bold;
}